<template>
  <article>
    <div class="ag-block__inner">
      <span v-if="note.featured_image" class="ag-block__featured-image">
        <Image :media="note.featured_image" :alt="note.title" conversion="square-thumb" width="100" lazy />
      </span>

      <div class="ag-block__body">
        <h3 v-if="note.title" class="ag-block__title">
          <a v-if="note.url" :href="note.url" :target="target" rel="nofollow noindex" v-html="$markdown(note.title)" @click.prevent="onClick(block)" />
          <span v-else v-html="$markdown(note.title)" />
        </h3>
        <p v-if="note.synopsis" class="ag-block__description" v-html="note.synopsis"></p>
        <div v-if="note.url && note.call_to_action_text" class="ag-block__cta">
          <Button :href="note.url" :target="target" as="a" class="ag-block__button" rel="nofollow noindex" @click.prevent="onClick(block)">
            <span>{{ note.call_to_action_text }}</span>
          </Button>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
}>();

const note: NoteBlock = props.block.item;

const target = note?.url_type === "link" ? "_blank" : undefined;

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-block--note-simple {
  .ag-block__featured-image {
    @apply w-[100px] flex-shrink-0;
  }

  .ag-block__body {
    @apply w-full;
  }

  .ag-block__description {
    @apply break-words;
  }

  &.ag-block--has-featured-image {
    @apply flex;
  }

  &.ag-block--has-featured-image {
    .ag-block__inner {
      @apply grid grid-cols-[100px_minmax(0,_1fr)] gap-6;
    }
  }
}
</style>
